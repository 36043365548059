<template>
  <div>
    <div
      v-if="template === 'default'"
      ref="trustbox"
      class="trustpilot-widget"
      data-locale="de-DE"
      data-template-id="5419b637fa0340045cd0c936"
      data-businessunit-id="647ee8f6dd4a73e0a9452b11"
      data-style-height="20px"
      data-style-width="100%"
      data-theme="dark"
    ></div>
    <div
      v-else-if="template === 'rating'"
      ref="trustbox"
      class="trustpilot-widget mb-8"
      data-locale="de-DE"
      data-template-id="56278e9abfbbba0bdcd568bc"
      data-businessunit-id="647ee8f6dd4a73e0a9452b11"
      data-style-height="52px"
      data-style-width="100%"
    ></div>
    <div
      v-else-if="template === 'checkout'"
      ref="trustbox"
      class="trustpilot-widget"
      data-locale="de-DE"
      data-template-id="53aa8807dec7e10d38f59f32"
      data-businessunit-id="647ee8f6dd4a73e0a9452b11"
      data-style-height="150px"
      data-style-width="100%"
      data-theme="light"
    ></div>
  </div>
</template>
<script setup lang="ts">
const store = useDefaultStore();
const shopwareStore = useShopwareStore();

const trustbox = ref(null);

useHead({
  script: [
    {
      src: "https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js",
      async: true,
    },
  ],
});

const props = defineProps({
  template: {
    type: String,
    default: "default",
  },
});

onMounted(() => {
  if (window.Trustpilot) {
    window.Trustpilot.loadFromElement(trustbox.value);
  }
});
</script>

<style lang="scss"></style>
